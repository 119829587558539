<template>
  <div>
    <div class="page-title">
      <h3>Рабочие смены</h3>
      <span class="valign-wrapper">
        <span v-if="!loading" style="margin-right: 10px">
          Неподтвержденных смен:
          <a class="btn-floating btn-large waves-effect waves-light red white-text"
             v-tooltip="'Проверить смены'"
             v-bind:class="{'red': unapprovedList.length > 0, 'blue': unapprovedList.length === 0, 'darken-4': unapprovedList.length === 0}"
             @click="openUnapprovedModal">
            {{ unapprovedList.length }}
          </a>
        </span>
        <span>
          <a class="btn-floating btn-large waves-effect waves-light blue darken-4 white-text"
             v-tooltip="'Скачать отчет'"
             @click="openReportModal">
            <i class="material-icons">assignment</i>
          </a>
        </span>
      </span>
    </div>

    <div class="row">
      <div class="col s2">
        <div class="input-field">
          <input id="date" type="text" v-model.lazy="selectedDate" class="datepicker" autocomplete="off"
                 v-datePicker="selectedDate">
          <label for="date">Дата</label>
        </div>
      </div>
      <div class="col s3">
        <div class="input-field">
          <select id="selected_working_shift" ref="workingShiftSelect" v-model="selectedWorkingShift">
            <option value="" disabled selected>Выбрать</option>
            <option v-for="r in workingShifts" :key="'ws_list_' + r.id" :value="r">
              <span v-bind:class="{'red-text': !r.approved}">
                {{ r.user.last_name }} {{ r.user.first_name }}
                ({{ r.created_at.seconds * 1000 | date('time') }} - {{ r.finished_at.seconds * 1000 | date('time') }})
              </span>
            </option>
          </select>
          <label for="selected_working_shift">Смена</label>
        </div>
      </div>
      <section v-if="canShowWorkingShift">
        <div class="col s2">
          <div class="input-field">
            <input id="team" type="text" v-model="selectedWorkingShift.team.name" v-bind:title="selectedWorkingShift.team.name" disabled>
            <label for="team">Бригада</label>
          </div>
        </div>
        <div class="col s1">
          <div class="input-field">
            <input id="engineer" type="text"
                   v-bind:value="getUserShortName(selectedWorkingShift.engineer)"
                   disabled>
            <label for="engineer">Инженер</label>
          </div>
        </div>
        <div class="col s2">
          <div class="input-field">
            <input id="released_at" type="text"
                   v-bind:value="workingShiftReleasedAt"
                   disabled>
            <label for="released_at">Завершено инженером</label>
          </div>
        </div>
        <div class="col s2">
          <div class="input-field">
            <input id="close_reason" type="text" v-bind:value="getCloseReason(selectedWorkingShift.close_reason)" v-bind:title="getCloseReason(selectedWorkingShift.close_reason)" disabled>
            <label for="close_reason">Причина закрытия</label>
          </div>
        </div>
      </section>
    </div>

    <div class="row" v-if="canShowWorkingShift">
      <div class="col s1">
        <div class="input-field">
          <i class="material-icons prefix blue-text text-darken-4"
             v-bind:title="workingShiftDriverTooltip">{{ workingShiftDriverIcon }}</i>
          <input id="theoretical_distance" type="text" disabled
                 v-bind:value="toKilometers(selectedWorkingShift.theoretical_distance)">
          <label for="theoretical_distance">Ожидаемый</label>
        </div>
      </div>
      <div class="col s1">
        <div class="input-field">
          <i class="material-icons prefix blue-text text-darken-4">location_on</i>
          <input id="factual_distance" type="text" v-model="factualDistance">
          <label for="factual_distance">Фактический</label>
        </div>
      </div>
      <div class="col s2">
        <div class="input-field">
          <i class="material-icons prefix blue-text text-darken-4">access_time</i>
          <input id="overtime10" type="text" v-model="overtime10" v-mask="'##:##'">
          <label for="overtime10">Сверхурочные х1.0</label>
          <small class="helper-text invalid" v-if="isInvalidOvertime10">Заполните сверхурочные ЧЧ:ММ</small>
        </div>
      </div>
      <div class="col s2">
        <div class="input-field">
          <i class="material-icons prefix blue-text text-darken-4">access_time</i>
          <input id="overtime15" type="text" v-model="overtime15" v-mask="'##:##'">
          <label for="overtime15">Сверхурочные х1.5 (до 2ч)</label>
          <small class="helper-text invalid" v-if="isInvalidOvertime15">Заполните сверхурочные ЧЧ:ММ</small>
        </div>
      </div>
      <div class="col s2">
        <div class="input-field">
          <i class="material-icons prefix blue-text text-darken-4">access_time</i>
          <input id="overtime20" type="text" v-model="overtime20" v-mask="'##:##'">
          <label for="overtime20">Сверхурочные х2.0</label>
          <small class="helper-text invalid" v-if="isInvalidOvertime20">Заполните сверхурочные ЧЧ:ММ</small>
        </div>
      </div>
      <div class="col s3">
        <div class="input-field">
          <textarea id="comment" class="materialize-textarea" v-model="selectedWorkingShift.comment"
                    data-length="255"></textarea>
          <label for="comment">Комментарий</label>
          <small class="helper-text invalid" v-if="isInvalidComment">Заполните комментарий</small>
        </div>
      </div>
      <div class="col s1">
        <div class="input-field">
          <a class="btn waves-effect waves-light blue darken-4" @click="approve" v-bind:disabled="isInvalidOvertime1 || isInvalidOvertime2 || isInvalidComment">
            Подтвердить
          </a>
        </div>
      </div>
    </div>

    <div class="row" v-if="canShowMap">
      <div class="col s2" style="height: 784px; overflow: auto">
        <div class="row">
          <h6>Объекты (всего заявок: {{tasks.length}})</h6>
          <div class="row">
            <div class="col" v-for="r in objects" :key="'object_list_' + r.id">
              <a @click="showObject(r)" v-bind:class="{'red-text': r === selectedPoint}">{{ getObjectName(r) }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <h6>Трекинг</h6>
          <div class="row">
            <div class="col">
              <div class="input-field">
                <select id="tracking_interval" ref="trackingIntervalSelect" v-model="trackingInterval">
                  <option v-for="interval in trackingIntervalList" :key="interval" :value="interval">{{
                      interval
                    }}
                  </option>
                </select>
                <label for="tracking_interval">Интервал трекинга, мин</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>
                <input :id="hideObjectInProgressTracking" type="checkbox" class="filled-in blue darken-4"
                       v-model="hideObjectInProgressTracking"/>
                <span>Скрыть трекинг на объектах</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col" v-for="r in tracking" :key="'tracking_list_' + r.id">
              <a @click="showLocationPoint(r)"
                 v-bind:class="{'red-text': r === selectedPoint}">{{ r.created_at.seconds * 1000 | date('time') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col s10">
        <blockquote>
          Для удобства пользователя на карте отображен список местоположений сотрудника с выбранным
          интервалом (от 1 до 10 минут) и не отображает детального движения сотрудника, по которому
          производится расчет пробега. Геолокации с плохой точностью местоположения или если их время
          попадает на время выполнения заявки - выделены желтым цветом и не будут учитываться при
          расчете пробега.
        </blockquote>

        <vl-map data-projection="EPSG:4326" style="height: 700px" ref="map">
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <vl-layer-tile>
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>

          <vl-feature v-for="(point, id) in tracking" :key="'tracking_point_list_' + point.id">
            <vl-geom-point v-bind:coordinates="getCoordinates(point.location)"></vl-geom-point>
            <vl-style-box>
              <vl-style-circle :radius="getPointRadius(id)">
                <vl-style-fill v-bind:color="getPointColor(point, id)"></vl-style-fill>
                <vl-style-stroke color="rgb(13, 71, 161)"></vl-style-stroke>
              </vl-style-circle>
              <vl-style-text v-bind:text="'  ' + getDate(point) + '(' + point.battery_capacity + '%)'"
                             font="bold 14px monospace"
                             textAlign="end"></vl-style-text>
            </vl-style-box>
          </vl-feature>

          <vl-feature>
            <vl-geom-line-string v-bind:coordinates="trackingLineCoordinates"></vl-geom-line-string>
            <vl-style-box>
              <vl-style-stroke color="rgb(13, 71, 161)" :width="3"></vl-style-stroke>
            </vl-style-box>
          </vl-feature>

          <vl-overlay v-for="feature in features" class="feature-popup" :key="'feature_list_' + feature.id"
                      :id="feature.id"
                      :position="pointOnSurface(feature.geometry)" positioning="top-center">
            <template slot-scope="popup">
              <section class="feature-card">
                <header class="feature-card-header" v-if="feature.type === 'object'">
                  {{ getObjectName(feature.object) }}
                </header>
                <div class="feature-card-content">
                  <div class="content">
                    <section v-if="feature.type === 'object'">
                      <a @click="showObjectTasks(feature.object)">
                        Заявок: {{ countObjectTasks(feature.object.id) }}
                      </a>
                    </section>
                    <section v-else-if="feature.type === 'tracking'">
                      {{ feature.date.seconds * 1000 | date('time') }}
                    </section>
                  </div>
                </div>
              </section>
            </template>
          </vl-overlay>

          <vl-feature v-for="(task, id) in tasks" :key="'object_point_list_' + task.id">
            <vl-geom-point v-bind:coordinates="getCoordinates(task.object.location)"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon src="/img/icons/location_green.png" :scale="0.6"></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </vl-map>
      </div>
    </div>

    <Loader v-if="loading"/>

    <div id="modalUnapproved" class="modal">
      <div class="modal-content" v-if="unapprovedList.length > 0">
        <h4>Список неподтвержденных смен</h4>
        <table>
          <thead>
          <tr>
            <th>Начало</th>
            <th>Окончание</th>
            <th>Исполнитель</th>
            <th>Теоретический</th>
            <th>Фактический</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(record, idx) of unapprovedList" :key="'unapproved_list_' + record.id">
            <td>
              {{ record.created_at.seconds * 1000 | date('datetime') }}
            </td>
            <td>
              {{ record.finished_at.seconds * 1000 | date('datetime') }}
            </td>
            <td>
              {{ record.user.last_name }} {{ record.user.first_name }}
            </td>
            <td>
              {{ toKilometers(record.theoretical_distance) }} км
            </td>
            <td>
              {{ toKilometers(record.factual_distance) }} км
            </td>
            <td>
              <a
                v-on:click="openWorkingShift(record)"
                v-tooltip="'Открыть маршрут'"
                class="material-icons">
                <i class="small material-icons">location_on</i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-content" v-else>
        <h4>Все смены подтверждены</h4>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

    <div id="modalTaskList" class="modal">
      <div class="modal-content" v-if="selectedObject">
        <h4>Выполненные заявки для объекта {{ getObjectName(selectedObject) }}</h4>
        <h6>{{ selectedObject.locality }}, {{ selectedObject.address }}</h6>
        <table>
          <thead>
          <tr>
            <th class="center-align">ID</th>
            <th>Название</th>
            <th>Тип</th>
            <th>Бригада</th>
            <th>Приступил</th>
            <th>Завершил</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(record, idx) of objectTasks" :key="'task_list_' + record.id">
            <td class="center-align">
              <a v-bind:href="('/tasks/' + record.id)" target="_blank" v-tooltip="'Открыть'"
                 class="btn-small btn blue darken-4">
                {{ record.id }}
              </a>
              <div v-if="record.external_id">
                <div class="chip no-margin" v-tooltip="'Внешний ID'">{{ record.external_id }}</div>
              </div>
            </td>
            <td>
              {{ record.title }}
              <div v-if="record.description !== ''" class="task_list_description">{{ record.description }}</div>
            </td>
            <td>
              {{ record.type }}
            </td>
            <td>{{ record.team.name }}</td>
            <td>
              {{ record.started_time.seconds * 1000 | date('time') }}
            </td>
            <td>
              {{ record.done_time.seconds * 1000 | date('time') }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

    <div id="modalReport" class="modal" style="height: 80%">
      <div class="modal-content">
        <h4>Загрузка отчета по подтвержденным пробегам в сменах</h4>
        <div class="row">
          <div class="col s2">
            <div class="input-field">
              <input id="date_from" type="text" v-model.lazy="dateFrom" class="datepicker" autocomplete="off"
                     v-datePicker="dateFrom">
              <label for="date_from">Начальная дата</label>
            </div>
          </div>
          <div class="col s2">
            <div class="input-field">
              <input id="date_to" type="text" v-model.lazy="dateTo" class="datepicker" autocomplete="off"
                     v-datePicker="dateTo">
              <label for="date_to">Конечная дата</label>
            </div>
          </div>
          <div class="col s3">
            <div class="input-field">
              <select id="customers_association" ref="customerAssociationSelect" v-model="selectedCustomerAssociation">
                <option value="" selected>Все</option>
                <option v-for="association in customerAssociations" :key="'customer_association_' + association" :value="association">
                  {{ association }}
                </option>
              </select>
              <label for="customers_association">Контрагент</label>
            </div>
          </div>
          <div class="col s2">
            <div class="input-field">
              <a class="btn waves-effect waves-light blue darken-4" @click="downloadReport">
                Загрузить
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>
import dateFilter from "@/filters/date.filter";
import convertDateString from "@/utils/date";
import {findPointOnSurface} from 'vuelayers/lib/ol-ext'
import {saveAs} from 'file-saver';
import iconv from 'pika-iconv-lite';
import {mask} from 'vue-the-mask';

export default {
  name: 'working_shifts',
  metaInfo() {
    return {
      title: this.$title('Рабочие смены')
    }
  },
  data: () => ({
    loading: true,
    engineerId: null,
    unapprovedList: [],
    workingShifts: [],
    tracking: [],
    tasks: [],
    selectedObject: null,
    objectTasks: [],
    factualDistance: 0,
    overtime10: null,
    overtime15: null,
    overtime15Max: 120,
    overtime20: null,
    selectedDate: "",
    canShowWorkingShift: false,
    hideObjectInProgressTracking: true,
    trackingInterval: 10,
    trackingIntervalList: [10, 5, 3, 1],
    selectedWorkingShift: null,
    selectedPoint: null,
    workingShiftSelect: null,
    trackingIntervalSelect: null,
    modalUnapproved: null,
    modalTaskList: null,
    modalReport: null,
    dateTo: null,
    dateFrom: null,
    zoom: 0,
    defaultZoom: 8,
    center: [],
    defaultCenter: [39.903098, 55.200564],
    customerAssociations: [],
    customerAssociationSelect: null,
    selectedCustomerAssociation: '',
    overtimeFormat: /^(\d{2}):(([0-5])\d)$/,
  }),
  async mounted() {
    let date = new Date()
    date.setDate(date.getDate() - 1)
    this.selectedDate = dateFilter(date.getTime(), 'date')

    date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    this.dateFrom = dateFilter(new Date(year, month - 1, 1).getTime(), 'date')
    this.dateTo = dateFilter(new Date(year, month, 0).getTime(), 'date')

    this.isAdmin = await this.$store.dispatch('profile/isAdmin')

    if (!this.isAdmin) {
      this.engineerId = await this.$store.dispatch('profile/getUid')
    }

    this.unapprovedList = await this.$store.dispatch('fetchNonApprovedWorkingShifts', this.engineerId)
    this.customerAssociations = await this.$store.dispatch('fetchCustomersAssociation')

    setTimeout(() => {
      M.updateTextFields()
      this.modalUnapproved = M.Modal.init(document.querySelector('#modalUnapproved'));
      this.modalTaskList = M.Modal.init(document.querySelector('#modalTaskList'));
      this.modalReport = M.Modal.init(document.querySelector('#modalReport'));
      this.customerAssociationSelect = M.FormSelect.init(this.$refs.customerAssociationSelect)
    })

    this.loading = false
  },
  watch: {
    selectedDate: async function (val) {
      let dateFrom = convertDateString(val)
      dateFrom.setHours(0)
      dateFrom.setMinutes(0)
      dateFrom.setSeconds(0)

      let dateTo = convertDateString(val)
      dateTo.setDate(dateTo.getDate() + 1)
      dateTo.setHours(0)
      dateTo.setMinutes(0)
      dateTo.setSeconds(0)

      let payload = {
        engineerId: this.engineerId,
        dateFrom: dateFrom.getTime() / 1000,
        dateTo: dateTo.getTime() / 1000,
      }
      this.workingShifts = await this.$store.dispatch('fetchCompletedWorkingShifts', payload)

      setTimeout(() => {
        this.workingShiftSelect = M.FormSelect.init(this.$refs.workingShiftSelect)
      })
    },
    selectedWorkingShift: async function (val) {
      if (!val) {
        return
      }

      this.tracking = await this.$store.dispatch('fetchWorkingShiftTracking', {
        wsId: this.selectedWorkingShift.id,
        skipInterval: this.trackingInterval,
        showRejected: !this.hideObjectInProgressTracking,
      })
      this.tasks = await this.$store.dispatch('getTasksForWorkingShift', this.selectedWorkingShift.id)
      this.factualDistance = this.toKilometers(this.selectedWorkingShift.factual_distance)
      this.overtime10 = this.convertOvertimeForWidget(this.selectedWorkingShift.overtime10)
      this.overtime15 = this.convertOvertimeForWidget(this.selectedWorkingShift.overtime15)
      this.overtime20 = this.convertOvertimeForWidget(this.selectedWorkingShift.overtime20)

      if (this.tracking.length >= 3) {
        let idx = Math.round(this.tracking.length / 2)
        this.center = [this.tracking[idx].location.lng, this.tracking[idx].location.lat]
        this.zoom = 10
      } else if (this.tracking.length > 0) {
        this.center = [this.tracking[0].location.lng, this.tracking[0].location.lat]
        this.zoom = 10
      } else {
        this.zoom = this.defaultZoom
        this.center = this.defaultCenter
      }

      this.canShowWorkingShift = true

      setTimeout(() => {
        M.updateTextFields()
        M.textareaAutoResize(document.getElementById('comment'))
        M.CharacterCounter.init(document.getElementById('comment'))
        this.workingShiftSelect = M.FormSelect.init(this.$refs.workingShiftSelect)
        this.trackingIntervalSelect = M.FormSelect.init(this.$refs.trackingIntervalSelect)
      })
    },
    workingShifts: function () {
      for (let i = 0; i < this.workingShifts.length; i++) {
        if (this.selectedWorkingShift && this.workingShifts[i].id === this.selectedWorkingShift.id) {
          this.canShowWorkingShift = true
          return
        }
      }

      this.canShowWorkingShift = false
    },
    hideObjectInProgressTracking: async function () {
      this.tracking = await this.$store.dispatch('fetchWorkingShiftTracking', {
        wsId: this.selectedWorkingShift.id,
        skipInterval: this.trackingInterval,
        showRejected: !this.hideObjectInProgressTracking,
      })
    },
    trackingInterval: async function () {
      this.tracking = await this.$store.dispatch('fetchWorkingShiftTracking', {
        wsId: this.selectedWorkingShift.id,
        skipInterval: this.trackingInterval,
        showRejected: !this.hideObjectInProgressTracking,
      })
    },
  },
  computed: {
    canShowMap: function () {
      return this.canShowWorkingShift && this.tracking.length > 0
    },
    trackingLineCoordinates: function () {
      let coords = []

      this.tracking.forEach(point => {
        coords.push([point.location.lng, point.location.lat])
      })

      return coords
    },
    features: function () {
      let features = []
      let objectTask = []

      this.tasks.forEach(task => {
        if (objectTask.indexOf(task.object.id) !== -1) {
          return
        }

        let coordinate = this.getCoordinates(task.object.location)

        features.push({
          id: 'object_' + task.id,
          type: 'object',
          geometry: {
            type: 'Point',
            coordinates: coordinate,
          },
          object: task.object,
        })

        objectTask.push(task.object.id)
      })

      return features
    },
    objects: function () {
      let objects = []
      let objectIds = []

      this.tasks.forEach(task => {
        if (objectIds.indexOf(task.object.id) === -1) {
          objectIds.push(task.object.id)
          objects.push(task.object)
        }
      })

      return objects
    },
    isInvalidComment: function () {
      if (this.selectedWorkingShift.comment.length < 5 || this.selectedWorkingShift.comment.length > 255) {
        return true
      }

      return false
    },
    isInvalidOvertime10: function () {
      return this.calculateOvertimeFromWidget(this.overtime10) === undefined
    },
    isInvalidOvertime15: function () {
      let overtime = this.calculateOvertimeFromWidget(this.overtime15)
      return overtime === undefined || overtime > this.overtime15Max
    },
    isInvalidOvertime20: function () {
      return this.calculateOvertimeFromWidget(this.overtime20) === undefined
    },
    workingShiftDriverTooltip: function () {
      if (this.selectedWorkingShift.driver) {
        return 'Водитель смены'
      } else {
        return 'Пешеход'
      }
    },
    workingShiftDriverIcon: function () {
      if (this.selectedWorkingShift.driver) {
        return 'directions_car'
      } else {
        return 'directions_walk'
      }
    },
    workingShiftReleasedAt: function () {
      if (this.selectedWorkingShift.released_at) {
        return dateFilter(this.selectedWorkingShift.released_at.seconds * 1000, 'time')
      } else {
        return 'Нет данных'
      }
    },
  },
  methods: {
    openUnapprovedModal() {
      this.modalUnapproved.open();
    },
    openReportModal() {
      this.modalReport.open();
    },
    openWorkingShift(ws) {
      this.selectedWorkingShift = ws
      this.selectedDate = dateFilter(ws.created_at.seconds * 1000, 'date')
      this.modalUnapproved.close();
    },
    toKilometers(meters) {
      return (meters / 1000).toFixed(2)
    },
    getCloseReason(alias) {
      const reasons = new Map([
        ['', 'Нет данных'],
        ['home_reached', 'Возвращение в домашнюю локацию'],
        ['ws_time_limit', 'Максимальная длительность смены'],
        ['tracking_timeout', 'Длительное отсутствие трекинга'],
        ['engineer_force_close', 'Принудительное завершение инженером'],
        ['estimated_timeout', 'Превышен интервал возвращения домой'],
        ['new_working_shift', 'Открытие новой смены'],
      ])

      return reasons.get(alias);
    },
    getUserShortName(user) {
      return user.last_name + ' ' + user.first_name.substring(0, 1) + '. ' + user.middle_name.substring(0, 1) + '.'
    },
    convertOvertimeForWidget(overtime) {
      if (overtime === null) {
        return overtime
      }

      let hours = Math.floor(overtime / 60);
      let minutes = overtime % 60

      if (hours < 10) {
        hours = "0" + hours
      }

      if (minutes < 10) {
        minutes = "0" + minutes
      }

      return hours + ":" + minutes
    },
    calculateOvertimeFromWidget(overtime) {
      if (overtime === null || overtime === "") {
        return undefined
      }

      if (overtime === 0 || overtime === "0") {
        return 0
      }

      let duration = this.overtimeFormat.exec(overtime)

      if (duration === null) {
        return undefined
      }

      let hours = duration[1] * 1 * 60
      let minutes = duration[2] * 1

      return hours + minutes
    },
    getCoordinates(location) {
      return [location.lng, location.lat]
    },
    getObjectName(obj) {
      let name = ""

      if (!obj) {
        return name
      }

      if (obj.external_id) {
        name = obj.external_id + ' (GSI ' + obj.id + ')'
      } else {
        name = obj.id
      }

      return name + ' ' + obj.locality
    },
    countObjectTasks(id) {
      let count = 0

      this.tasks.forEach(task => {
        if (task.object.id === id) {
          count++
        }
      })

      return count
    },
    showObjectTasks(object) {
      this.selectedObject = {
        id: object.id,
        external_id: object.external_id,
        locality: object.locality,
        address: object.address,
      }
      this.objectTasks = []
      this.tasks.forEach(task => {
        if (task.object.id === object.id) {
          this.objectTasks.push(task)
        }
      })

      this.modalTaskList.open();
    },
    getPointColor(point, id) {
      if (this.selectedPoint !== null && this.selectedPoint.id === point.id) {
        return `rgb(244, 57, 64)`
      }

      let baseRed = 13
      let baseGreen = 71
      let baseBlue = 161

      if (point.accuracy >= 200 || point.rejected === true) {
        baseRed = 255
        baseGreen = 255
        baseBlue = 0
      } else {
        let targetRed = 255
        let targetGreen = 255
        let targetBlue = 255

        let distanceRed = targetRed - baseRed
        let distanceGreen = targetGreen - baseGreen
        let distanceBlue = targetBlue - baseBlue

        let step = id / this.tracking.length
        baseRed += distanceRed * step
        baseGreen += distanceGreen * step
        baseBlue += distanceBlue * step
      }

      return `rgb(${baseRed}, ${baseGreen}, ${baseBlue})`
    },
    getPointRadius(idx) {
      if (idx === 0 || idx === this.tracking.length - 1) {
        return 10
      }

      return 5
    },
    getDate(point) {
      return dateFilter(point.created_at.seconds * 1000, 'time')
    },
    showLocationPoint(point) {
      this.selectedPoint = point
      this.center = [point.location.lng, point.location.lat]
      this.zoom = 13
    },
    showObject(object) {
      this.selectedPoint = object
      this.showObjectTasks(object)
      this.center = [object.location.lng, object.location.lat]
      this.zoom = 13
    },
    async approve() {
      let payload = {
        id: this.selectedWorkingShift.id,
        factual_distance: this.factualDistance.replace(/,/, '.') * 1000,
        comment: this.selectedWorkingShift.comment,
        overtime10: this.calculateOvertimeFromWidget(this.overtime10),
        overtime15: this.calculateOvertimeFromWidget(this.overtime15),
        overtime20: this.calculateOvertimeFromWidget(this.overtime20),
      }
      await this.$store.dispatch('approveWorkingShift', payload)

      for (let i = 0; i < this.unapprovedList.length; i++) {
        if (this.unapprovedList[i].id === this.selectedWorkingShift.id) {
          this.unapprovedList.splice(i, 1)
        }
      }

      for (let i = 0; i < this.workingShifts.length; i++) {
        if (this.workingShifts[i].id === this.selectedWorkingShift.id) {
          this.workingShifts[i].approved = true
          this.workingShifts[i].overtime10 = payload.overtime10
          this.workingShifts[i].overtime15 = payload.overtime15
          this.workingShifts[i].overtime20 = payload.overtime20
        }
      }

      this.selectedWorkingShift = null
      this.canShowWorkingShift = false

      M.toast({html: 'Успешно подтверждено'})

      setTimeout(() => {
        this.workingShiftSelect = M.FormSelect.init(this.$refs.workingShiftSelect)
      })
    },
    async downloadReport() {
      let payload = {
        date_from: convertDateString(this.dateFrom + ' 00:00:00').getTime() / 1000,
        date_to: convertDateString(this.dateTo + ' 23:59:59').getTime() / 1000,
        customer_association: this.selectedCustomerAssociation,
      }

      let file = await this.$store.dispatch('reportWorkingShift', payload)
      let fileName = 'Отчет по сменам с '
      fileName += ' ' + dateFilter(payload.date_from * 1000, 'date')
      fileName += ' по ' + dateFilter(payload.date_to * 1000, 'date')

      if (this.selectedCustomerAssociation !== null && this.selectedCustomerAssociation !== "") {
        fileName += ' контрагент ' + this.selectedCustomerAssociation
      }

      fileName += '.csv'

      saveAs(
        new Blob([iconv.encode(file, 'win1251')], {type: `application/vnd.ms-excel;charset=windows-1251`}),
        fileName,
      );
    },
    pointOnSurface: findPointOnSurface,
  },
  components: {},
  directives: {mask},
  destroyed() {
    this.modalUnapproved.destroy();
    this.modalTaskList.destroy();
    this.modalReport.destroy();

    if (this.workingShiftSelect && this.workingShiftSelect.destroy) {
      this.workingShiftSelect.destroy()
    }

    if (this.customerAssociationSelect && this.customerAssociationSelect.destroy) {
      this.customerAssociationSelect.destroy()
    }
  },
}
</script>


<style scoped>
.feature-popup {
  margin-top: 20px;
  text-align: center;
}

.feature-card {
  font-size: 0.8rem;
  text-shadow: 0 0 2px white;
  font-weight: bold;
}
</style>
